import React, { useEffect, useState } from 'react'
import config from '../coreFIles/config';
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import Header from '../directives/header'
import Footer from '../directives/footer'
import Sidebar from '../directives/sidebar'

import { CreateUserAction, getSearchSuburbAction, getMonthlySpendlistAction, getTieredGrouplistAction, getindustrylistAction } from '../Action/action';

const CreateUser = () => {	
	const [form, setForm] = useState({ first_name: '', last_name: '', email: '', password: '', phone: '', company: '', industry: '', address: '', suburb: '', state: '', postcode: '', abn: '', monthly_spend: '', is_cod: '0', product_range: 0, minimum_order_total: '', free_shipping: '', minimum_amout_for_free_shipping: '', tiered_group: '', is_active: '', country: 'AU' });
	const [validationError, setvalidationError] = useState({});
	const [SuburbsearchResults, setSuburbsearchResults] 	= useState([]);
	const [industry, setindustry] 	= useState([]);
	const [tiered_group, settiered_group] 	= useState([]);
	const [monthly_spend, setmonthly_spend] 	= useState([]);
	const [suburb, setsuburb] 	= useState('');
	const [postcode, setpostcode] 	= useState('');
	const [showcod, setshowcod] 	= useState(0);
	useEffect(()=>{
		getindustryAPI();
		gettiered_groupAPI();
		getmonthly_spendAPI();
				
    },[]);
    
    const getindustryAPI = async () => {
		let res = await getindustrylistAction();
		if (res.success) {
			let data = res.data;			
			setindustry(data);	
		}
		else
		{
			setindustry([]);	
		}
	}
    const gettiered_groupAPI = async () => {
		let res = await getTieredGrouplistAction();
		if (res.success) {
			let data = res.data;			
			settiered_group(data);	
		}
		else
		{
			settiered_group([]);	
		}
	}
    const getmonthly_spendAPI = async () => {
		let res = await getMonthlySpendlistAction();
		if (res.success) {
			let data = res.data;			
			setmonthly_spend(data);	
		}
		else
		{
			setmonthly_spend([]);	
		}
	}
	
	const inputHandler = async (e) => {
		const { name, value } = e.target
		setForm((old) => {
			return { ...old, [name]: value }
		})
	}
	const inputmonthly_spendHandler = async (e) => {
		const { name, value } = e.target
		setForm((old) => {
			return { ...old, [name]: value }
		})
		if(value ==='')
		{
			setshowcod(0)
		}
		else if(value !='1')
		{
			setshowcod(1)
		}
		else
		{
			setshowcod(0)
		}
	}
	const handleKeyPress = (e) => {
		// Allow only numeric values (0-9) and certain control keys
		const isValidInput = /^[0-9\b]+$/.test(e.key);

		if (!isValidInput) {
		  e.preventDefault();
		}
	  }; 
	const SearchSuburb = async (str) => {
		try {
			setsuburb(str);
			setpostcode('');
			let res = await getSearchSuburbAction({str:str});
			setSuburbsearchResults(res.data);
		}
		catch (error) {
			console.error(error);
		}
	};	   
    const handleSelectSuburb = (row) => {
	   console.log(row)
		setsuburb(row.suburb);
		setpostcode(row.postcode);
		setForm((old) => {
			return { ...old, 'suburb': row.suburb };
		});
		setForm((old) => {
			return { ...old, 'postcode': row.postcode };
		});
		setSuburbsearchResults({});
	};	
	
   function validate() {	   
		let first_nameError = "";
		let last_nameError = "";
		let emailError = "";
		let passwordError = "";
		let phoneError = "";
		let companyError = "";
		let industryError = "";
		let addressError = "";
		let suburbError = "";
		let stateError = "";
		let postcodeError = "";
		let abnError = "";
		let monthly_spendError = "";
		let is_codError = "";
		let minimum_order_totalError = "";
		let free_shippingError = "";
		let minimum_amout_for_free_shippingError = "";
		let tiered_groupError = "";		
		let is_activeError = "";
		
		if (form.first_name === '') {
		  first_nameError = "First name is required."
		}
		if (form.last_name === '') {
			last_nameError = "Last name is required."
		}
		if (form.email === '') {
			emailError = "Email is required."
		}
		if (form.password === '') {
			passwordError = "Password is required."
		}
		if (form.phone === '') {
			phoneError = "Phone is required."
		}
		if (form.company === '') {
			companyError = "Company name is required."
		}
		if (form.industry === '') {
			industryError = "Industry is required."
		}
		if (form.address === '') {
			addressError = "Address is required."
		}
		if (form.suburb === '') {
			suburbError = "Suburb is required."
		}
		if (form.state === '') {
			stateError = "State is required."
		}
		if (form.postcode === '') {
			postcodeError = "Postcode is required."
		}
		if (form.abn === '') {
			abnError = "ABN is required."
		}
		if (form.monthly_spend === '') {
			monthly_spendError = "Monthly spend is required."
		}
		if (showcod==1 && form.is_cod === '') {
			is_codError = "COD or a 30 day is required."
		}
		if (form.minimum_order_total === '') {
			minimum_order_totalError = "Minimum order total is required."
		}
		if (form.tiered_group === '') {
			tiered_groupError = "Tiered group is required."
		}
		if (form.is_active === '') {
			is_activeError = "Status is required."
		}
		
		if(form.email != '')
		{
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			if(emailRegex.test(form.email) === false)
			{
				emailError = "Please enter valid email."
			}
		}		
		if (first_nameError || last_nameError || emailError || passwordError || phoneError || companyError || industryError || addressError|| suburbError|| stateError|| postcodeError|| abnError|| monthly_spendError|| is_codError|| minimum_order_totalError|| free_shippingError|| minimum_amout_for_free_shippingError|| tiered_groupError|| is_activeError) {
			setvalidationError({
			  first_nameError, last_nameError, emailError, passwordError, phoneError, companyError, industryError, addressError, suburbError, stateError, postcodeError, abnError, monthly_spendError, is_codError, minimum_order_totalError, free_shippingError, minimum_amout_for_free_shippingError, tiered_groupError, is_activeError
			})
			return false
		} else {
			setvalidationError({
			  first_nameError, last_nameError, emailError, passwordError, phoneError, companyError, industryError, addressError, suburbError, stateError, postcodeError, abnError, monthly_spendError, is_codError, minimum_order_totalError, free_shippingError, minimum_amout_for_free_shippingError, tiered_groupError, is_activeError
			})
			return true
		}
	}
	
	const SubmitForm = async (e) => {
		e.preventDefault()
		const isValid = validate();
		if (!isValid) {

		}
		else {
			let res = await CreateUserAction(form);
			if (res.success) {
				toast.success(res.msg);
				setTimeout(() => {
					window.location.href = `${config.baseUrl}users`;
				}, 1200);
			} else {
				toast.error(res.msg);
			}
		}
	}
 

  return (

    <>
      <Sidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <Toaster />
        <Header />
         <div className="container-lg mb-4">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb my-0 ms-2">
              <li className="breadcrumb-item">
               <span><a className="homelink" href={`${config.baseUrl}users`} >Home</a></span>
              </li>
              <li className="breadcrumb-item active"><span>Create User</span></li>
            </ol>
          </nav>
        </div>
        <div className="body flex-grow-1 px-3">
        <div className="container-lg">
          <div className="row">
            
          <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header">
					<div className="row align-items-center">				
						<div className="col-md-6 text-start">
						 <span>Create User</span>
						</div>
						<div className="col-md-6 text-end">
							<a className="btn btn-primary editbtn" href={`${config.baseUrl}users`} >Back</a>
						</div>
					</div>
                </div>
                <div className="card-body inner-body">                
                 <form className="row g-3">
					 <div className="col-md-6">
						<label className="form-label" for="first_name">First name<span className="req-star">*</span></label>
						<input className={validationError.first_nameError ? 'form-control is-invalid' : 'form-control'} id="first_name" name="first_name" placeholder="Enter first name" type="text" onChange={inputHandler} />
						<div className="invalid-feedback">{validationError.first_nameError}</div>
					  </div>
					  <div className="col-md-6">
						<label className="form-label" for="last_name">Last name</label>
						<input className="form-control" id="last_name" name="last_name" placeholder="Enter last name" type="text" onChange={inputHandler} />
					  </div> 
					  <div className="col-md-6">
						<label className="form-label" for="email">Email<span className="req-star">*</span></label>
						<input className={validationError.emailError ? 'form-control is-invalid' : 'form-control'} id="email" name="email" placeholder="Enter email address" type="text" onChange={inputHandler} autocomplete="off" />
						<div className="invalid-feedback">{validationError.emailError}</div>
					  </div>
				     <div className="col-md-6">
						<label className="form-label" for="password">Password<span className="req-star">*</span></label>
						<input className={validationError.passwordError ? 'form-control is-invalid' : 'form-control'} id="password" name="password" placeholder="Enter password" type="password" onChange={inputHandler} autocomplete="off" />
						<div className="invalid-feedback">{validationError.passwordError}</div>
					  </div>					  
					 <div className="col-md-6">
						<label className="form-label" for="phone">Phone<span className="req-star">*</span></label>
						<input className={validationError.phoneError ? 'form-control is-invalid' : 'form-control'} id="phone" name="phone" placeholder="Enter phone number" type="text" onChange={inputHandler} onKeyPress={handleKeyPress} maxlength="10"/>
						<div className="invalid-feedback">{validationError.phoneError}</div>
					  </div>					  
					  <div className="col-md-6">
						<label className="form-label" for="company">Company name<span className="req-star">*</span></label>
						<input className={validationError.companyError ? 'form-control is-invalid' : 'form-control'}  id="company" name="company" placeholder="Enter company name" type="text" onChange={inputHandler} />
						<div className="invalid-feedback">{validationError.companyError}</div>
					  </div> 					  
					  <div className="col-md-6">
						<label className="form-label" for="industry">Industry<span className="req-star">*</span></label>
						<select className={validationError.industryError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="industry" name="industry" onChange={inputHandler}>
                              <option value="">Select Industry</option>
                               {industry.length > 0 ? (
									industry.map((ind) => (
										<option key={ind.id} value={ind.id}>{ind.title}</option>
									))
								) : null}                              					
                            </select>
                            <div className="invalid-feedback">{validationError.industryError}</div>
					  </div>
					   <div className="col-md-6">
						<label className="form-label" for="abn">ABN<span className="req-star">*</span></label>
						<input className={validationError.abnError ? 'form-control is-invalid' : 'form-control'} id="abn" name="abn" placeholder="Enter ABN" type="text" onChange={inputHandler} />
						<div className="invalid-feedback">{validationError.abnError}</div>
					  </div> 
					   <div className="col-md-12">
						<label className="form-label" for="address">Address<span className="req-star">*</span></label>
						<input className={validationError.addressError ? 'form-control is-invalid' : 'form-control'}  id="address" name="address" placeholder="Enter address" type="text" onChange={inputHandler} />
						<div className="invalid-feedback">{validationError.addressError}</div>
					  </div> 					  
					  <div className="col-md-6">
						<label className="form-label" for="suburb">Suburb<span className="req-star">*</span></label>
						<input className={validationError.suburbError ? 'form-control is-invalid' : 'form-control'} id="suburb" name="suburb" placeholder="Enter suburb" type="text" onChange={(e) => SearchSuburb(e.target.value)} autocomplete="off" value={suburb} />
						<span className="invalid-feedback">{validationError.suburbError}</span>
						{SuburbsearchResults.length > 0 ? (
							<ul className="suburbList">
								{SuburbsearchResults.map((row) => (
									<li key={row.id} onClick={() => handleSelectSuburb(row)}>
										{row.rowlocation} 
									</li>
								))}
							</ul>
						) : null}
					  </div>
					   <div className="col-md-6">
						<label className="form-label" for="postcode">Postcode<span className="req-star">*</span></label>
						<input className={validationError.postcodeError ? 'form-control is-invalid' : 'form-control'} id="postcode" name="postcode" value={postcode} placeholder="Enter postcode" type="text" readonly disabled />
						<div className="invalid-feedback">{validationError.postcodeError}</div>
					  </div> 
					   <div className="col-md-6">
						<label className="form-label" for="state">State<span className="req-star">*</span></label>
						<select className={validationError.stateError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="state" name="state" onChange={inputHandler}>
                              <option value="">Select state</option>
                              <option value="ACT">Australian Capital Territory</option>
							  <option value="NSW">New South Wales</option>
							  <option value="NT">Northern Territory</option>
							  <option value="QLD">Queensland</option>
							  <option value="SA">South Australia</option>
							  <option value="TSA">Tasmania</option>
							  <option value="VIC">Victoria</option>
							  <option value="WA">Western Australia</option>					
                            </select>
						<div className="invalid-feedback">{validationError.stateError}</div>
					  </div> 
					   <div className="col-md-6">
						<label className="form-label" for="minimum_order_total">Minimum order total<span className="req-star">*</span></label>
						<input className={validationError.minimum_order_totalError ? 'form-control is-invalid' : 'form-control'} id="minimum_order_total" name="minimum_order_total" placeholder="Enter minimum order total" type="text" onChange={inputHandler} onKeyPress={handleKeyPress}/>
						<div className="invalid-feedback">{validationError.minimum_order_totalError}</div>
					  </div> 
					  <div className="col-md-6">
						<label className="form-label" for="monthly_spend">Average monthly spend<span className="req-star">*</span></label>
						<select className={validationError.monthly_spendError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="monthly_spend" name="monthly_spend" onChange={inputmonthly_spendHandler}>
                              <option value="">Select average monthly spend</option>
                               {monthly_spend.length > 0 ? (
									monthly_spend.map((ms) => (
										<option key={ms.id} value={ms.id}>{ms.title}</option>
									))
								) : null} 					
                            </select>
                            <div className="invalid-feedback">{validationError.monthly_spendError}</div>
					  </div>
					  {showcod==1 ?
						  (
						  <div className="col-md-6">
								<label className="form-label" for="is_cod">Would you like to be on a COD or a 30 day account?<span className="req-star">*</span></label>
								<select className={validationError.is_codError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="is_cod" name="is_cod" onChange={inputHandler}>
									  <option value="">Select</option>
									  <option value="1">COD</option>
									  <option value="2">30 Day Account</option>				
									</select>
									<div className="invalid-feedback">{validationError.is_codError}</div>
							  </div>
						  ):''				  
						  
						  }
					  <div className="col-md-6">
						<label className="form-label" for="free_shipping">Free shipping<span className="req-star">*</span></label>
						<select className={validationError.free_shippingError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="free_shipping" name="free_shipping" onChange={inputHandler}>
							  <option value="">Select</option>
							  <option value="0">No</option>
							  <option value="1">Yes</option>				
							</select>
							<div className="invalid-feedback">{validationError.free_shippingError}</div>
					  </div>
					  <div className="col-md-6">
						<label className="form-label" for="minimum_amout_for_free_shipping">Minimum ammount for free shipping<span className="req-star">*</span></label>
						<input className={validationError.minimum_amout_for_free_shippingError ? 'form-control is-invalid' : 'form-control'} id="minimum_amout_for_free_shipping" name="minimum_amout_for_free_shipping" placeholder="Enter minimum order total" type="text" onChange={inputHandler} onKeyPress={handleKeyPress} />
						<div className="invalid-feedback">{validationError.minimum_amout_for_free_shippingError}</div>
					  </div>
					  <div className="col-md-6">
						<label className="form-label" for="tiered_group">Tiered group<span className="req-star">*</span></label>
						<select className={validationError.tiered_groupError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="tiered_group" name="tiered_group" onChange={inputHandler}>
							  <option value="">Select</option>
							  {tiered_group.length > 0 ? (
									tiered_group.map((tg) => (
										<option key={tg.id} value={tg.id}>{tg.title}</option>
									))
								) : null} 			
							</select>
							<div className="invalid-feedback">{validationError.tiered_groupError}</div>
					  </div>
					  <div className="col-md-6">
						<label className="form-label" for="is_active">Status<span className="req-star">*</span></label>
						<select className={validationError.is_activeError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="is_active" name="is_active" onChange={inputHandler}>
							  <option value="">Select Status</option>
							  <option value="0">In-active</option>
							  <option value="1">Active</option>				
							</select>
							<div className="invalid-feedback">{validationError.is_activeError}</div>
					  </div>
					  
					  
					  <div className="col-12 text-end">
						<button className="btn btn-primary" type="submit" onClick={SubmitForm} >Save</button>
					  </div>
					  					  
				  </form>                
                </div>
              </div>
            </div>
          </div>          
        </div>
      </div>    
      <Footer />
      </div>
    </>
  )

}
export default CreateUser;
