import React, { useState } from "react";
import config from "../coreFIles/config";
import Cookies from "js-cookie";

const Sidebar = () => {
	
  const loginData = (!Cookies.get('loginSuccessfastenerwarehouseAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessfastenerwarehouseAdmin'));
  if (!loginData || loginData == '') {
    window.location.href = `${config.baseUrl}`;
  }
	
	
  const [pageUrl, setPageUrl] = useState(window.location.href);
  const [isToggled, setIsToggled] = useState(false);

  const logout = async () => {
    Cookies.remove("loginSuccessfastenerwarehouseAdmin");
    window.location.href = config.baseUrl;
  };
  const toggleClass = () => {
    setIsToggled(!isToggled);
  };
	
  return (
    <>    
    
    <div className="sidebar sidebar-dark sidebar-fixed" id="sidebar">
      <div className="sidebar-brand d-none d-md-flex logo-head">
       <img className="logo-img" src={"logo.png"} alt="logo" width="118" height="46"  />
        
      </div>
      <ul className="sidebar-nav" data-coreui="navigation" data-simplebar="">
        
			<li className="nav-item"><a className={pageUrl.match('/dashboard') ? 'active nav-link' : 'nav-link'} href={`${config.baseUrl}dashboard`}>
            <span><img src={`${config.baseUrl}images/module.png`} /></span> Dashboard</a></li>      
            
            <li className="nav-item"><a className={pageUrl.match('/user') ? 'active nav-link' : 'nav-link'} href={`${config.baseUrl}users`}>
           <span><i className="fa-solid fa-users"></i></span> Users</a></li>
           
           <li className="nav-item"><a className={pageUrl.match('/categories') ? 'active nav-link' : 'nav-link'} href={`${config.baseUrl}categories`}>
           <span><i className="fa-solid fa-list"></i></span> Categories</a></li>            
           <li className="nav-item"><a className={pageUrl.match('/filter') ? 'active nav-link' : 'nav-link'} href={`${config.baseUrl}filters`}>
           <span><i className="fa-solid fa-filter"></i></span> Filters</a></li>  
            
           <li className="nav-item"><a className={pageUrl.match('/product') ? 'active nav-link' : 'nav-link'} href={`${config.baseUrl}products`}>
           <span><i className="fa-solid fa-cube"></i></span> Products</a></li>           
            
             
           <li className="nav-item"><a className={pageUrl.match('/order') ? 'active nav-link' : 'nav-link'} href={`${config.baseUrl}orders`}>
           <span><i className="fa-solid fa-pen-to-square"></i></span> Orders</a></li>   
           
           <li className="nav-item"><a className={pageUrl.match('/setting') ? 'active nav-link' : 'nav-link'} href={`${config.baseUrl}settings`}>
           <span><i className="fa-solid fa-gear"></i></span> Settings</a></li>   
        
        </ul>
             
    </div>
    
    </>
  );
};
export default Sidebar;
