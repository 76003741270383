import './App.css';
import config from "./coreFIles/config";
import Login from './component/login';
import Dashboard from "./component/dashboard";
import Categories from "./component/categories";
import Subcategory from "./component/subcategories";
import Filters from "./component/filters";
import Users from "./component/users";
import CreateUser from "./component/createuser";
import EditUser from "./component/edituser";
import Settings from './component/Settings'
import Products from './component/products'
import Orders from './component/orders'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path={`${config.baseUrl}`} element={<Login />} />
          <Route path={`${config.baseUrl}dashboard`} element={<Dashboard />} />         
          <Route path={`${config.baseUrl}categories`} element={<Categories />} />  
          <Route path={`${config.baseUrl}sub-category/:id`} element={<Subcategory />} />        
          <Route path={`${config.baseUrl}filters`} element={<Filters />} />        
          <Route path={`${config.baseUrl}users`} element={<Users />} />        
          <Route path={`${config.baseUrl}ad-user`} element={<CreateUser />} /> 
          <Route path={`${config.baseUrl}edit-user/:id`} element={<EditUser />} /> 
          <Route path={`${config.baseUrl}settings`} element={<Settings />}  />         
          <Route path={`${config.baseUrl}products`} element={<Products />}  />         
          <Route path={`${config.baseUrl}orders`} element={<Orders />}  />         
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
